/*--------------------------------------------------------------------------------------------
*
*	acf-field
*
*--------------------------------------------------------------------------------------------*/

.acf-field,
.acf-field .acf-label,
.acf-field .acf-input {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
}

.acf-field {
	margin: 15px 0;
	
	// clear is important as it will avoid any layout issues with floating fields
	// do not delete (you have tried this)
	clear: both;
	
	// description
	p.description {
		display: block;
		margin: 0;
		padding: 0;
	}
	
	// label
	.acf-label {
		vertical-align: top;
		margin: 0 0 10px;
		
		label {
		    display: block;
		    font-weight: bold;
		    margin: 0 0 3px;
			padding: 0;
		}
		
		&:empty {
			margin-bottom: 0;
		}
	}
	
	// input
	.acf-input {
		vertical-align: top;
		
		> p.description {
			margin-top: 5px;
		}
	}
	
	// notice
	.acf-notice {
		margin: 0 0 15px;
		background: #edf2ff;
		color: #0c6ca0;
		border-color: #2183b9;

		// error
		&.-error {
			background: #ffe6e6;
			color: #cc2727;
			border-color: #d12626;
		}
		
		// success
		&.-success {
			background: #eefbe8;
			color: #0e7b17;
			border-color: #32a23b;
		}
		
		// warning
		&.-warning {
			background: #fff3e6;
			color: #bd4b0e;
			border-color: #d16226;
		}
	}
	
	// table
	@at-root td#{&}, tr#{&} {
		margin: 0;
	}
}

// width
.acf-field[data-width] {
	float: left;
	clear: none;
	
	// next
	+ .acf-field[data-width] {
		border-left: 1px solid #eeeeee;	
	}
	
	// rtl
	html[dir="rtl"] & {
		float: right;
		
		+ .acf-field[data-width] {
			border-left: none;
			border-right: 1px solid #eeeeee;	
		}
	}
	
	// table
	@at-root td#{&}, tr#{&} {
		float: none;
	}
	
	// mobile
/*
	@media screen and (max-width: $sm) {
		float: none;
		width: auto;
		border-left-width: 0;
		border-right-width: 0;
	}
*/
}


// float helpers
.acf-field.-c0 {
	clear: both;
	border-left-width: 0 !important;
	
	// rtl
	html[dir="rtl"] & {
		border-left-width: 1px !important;
		border-right-width: 0 !important;
	}
}

.acf-field.-r0 {
	border-top-width: 0 !important;
}


/*--------------------------------------------------------------------------------------------
*
*	acf-fields
*
*--------------------------------------------------------------------------------------------*/

.acf-fields {
	position: relative;
	
	// clearifx
	@include clearfix();
	
	// border
	&.-border {
		border: $wp-card-border solid 1px;
		background: #fff;
	}
	
	// field
	> .acf-field {
		position: relative;
		margin: 0;
		padding: $field_padding;
		border-top: #EEEEEE solid 1px;
		
		// first
		&:first-child {
			border-top: none;
			margin-top: 0;
		}
	}
	
	// table
	@at-root td#{&} {
		padding: 0 !important;
	}
}



/*--------------------------------------------------------------------------------------------
*
*	acf-fields (clear)
*
*--------------------------------------------------------------------------------------------*/

.acf-fields.-clear > .acf-field {
	border: none;
	padding: 0;
	margin: 15px 0;
	
	// width
	&[data-width] {
		border: none !important;
	}
	
	// label
	> .acf-label {
		padding: 0;
	}
	
	// input
	> .acf-input {
		padding: 0;
	}
}


/*--------------------------------------------------------------------------------------------
*
*	acf-fields (left)
*
*--------------------------------------------------------------------------------------------*/

.acf-fields.-left > .acf-field {
	padding: $fy 0;
	
	// clearifx
	@include clearfix();
	
	// sidebar
	&:before {
		content: "";
	    display: block;
	    position: absolute;
	    z-index: 0;
	    background: #F9F9F9;
	    border-color: #E1E1E1;
	    border-style: solid;
	    border-width: 0 1px 0 0;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    width: 20%;
	}
	
	// width
	&[data-width] {
		float: none;
		width: auto !important;
		border-left-width: 0 !important;
		border-right-width: 0 !important;
	}
	
	// label
	> .acf-label {
		float: left;
		width: 20%;
		margin: 0;
		padding: 0 $fx;
	}
	
	// input
	> .acf-input {
		float: left;
		width: 80%;
		margin: 0;
		padding: 0 $fx;
	}
	
	// rtl
	html[dir="rtl"] & {
		
		// sidebar
		&:before {
			border-width: 0 0 0 1px;
			left: auto;
			right: 0;
		}
		
		// label
		> .acf-label {
			float: right;
		}
		
		// input
		> .acf-input {
			float: right;
		}
	}
	
	// In sidebar.
	#side-sortables & {
		&:before { 
			display: none;
		}
		> .acf-label {
			width: 100%;
			margin-bottom: 10px;
		}
		> .acf-input {
			width: 100%;
		}
	}
	
	// mobile
	@media screen and (max-width: $sm) {
		
		// sidebar
		&:before { 
			display: none;
		}
		
		// label
		> .acf-label {
			width: 100%;
			margin-bottom: 10px;
		}
		
		// input
		> .acf-input {
			width: 100%;
		}
	}
}

/* clear + left */
.acf-fields.-clear.-left > .acf-field {
	padding: 0;
	border: none;
	
	// sidebar
	&:before {
		display: none;
	}
	
	// label
	> .acf-label {
		padding: 0;
	}
	
	// input
	> .acf-input {
		padding: 0;
	}
}


/*--------------------------------------------------------------------------------------------
*
*	acf-table
*
*--------------------------------------------------------------------------------------------*/

.acf-table tr.acf-field {
	
	// label
	> td.acf-label {
		padding: $fp;
		margin: 0;
		background: #F9F9F9;
	    width: 20%;
	}
	
	// input
	> td.acf-input {
		padding: $fp;
		margin: 0;
		border-left-color: #E1E1E1;
	}
}

.acf-sortable-tr-helper {
	position: relative !important;
	display: table-row !important;
}


/*--------------------------------------------------------------------------------------------
*
*	acf-postbox
*
*--------------------------------------------------------------------------------------------*/

.acf-postbox {
	position: relative;
	
	// inside
	> .inside {
		margin: 0 !important; /* override WP style - do not delete - you have tried this before */
		padding: 0 !important; /* override WP style - do not delete - you have tried this before */
	}

	// Edit cog.
	.acf-hndle-cog {
		color: #72777c;
		font-size: 16px;
		line-height: 36px;
		height: 36px; // Mimic WP 5.5
		width: 1.62rem; // Mimic WP 5.5
		position: relative;
		display: none;
		&:hover {
			color: #191e23;
		}
	}

	// Show on hover.
	> .hndle:hover,
	> .postbox-header:hover {
		.acf-hndle-cog {
			display: inline-block;
		}
	}

	// WP < 5.5 styling
	> .hndle {
		.acf-hndle-cog {
			height: 20px;
			line-height: 20px;
			float: right;
			width: auto;
			&:hover {
				color: #777777;
			}
		}
	}
	
	// replace
	.acf-replace-with-fields {
		padding: 15px;
		text-align: center;
	}
}

// Correct margin around #acf_after_title
#post-body-content #acf_after_title-sortables {
	margin: 20px 0 -20px;
}

/* seamless */
.acf-postbox.seamless {
	border: 0 none;
	background: transparent;
	box-shadow: none;
	
	
	/* hide hndle */
	> .postbox-header,
	> .hndle,
	> .handlediv {
		display: none !important;
	}
	
	
	/* inside */
	> .inside {
		display: block !important; /* stop metabox from hiding when closed */
		margin-left: -$field_padding_x !important;
		margin-right: -$field_padding_x !important;
		
		> .acf-field {
			border-color: transparent;
		}
	}
}


/* seamless (left) */
.acf-postbox.seamless > .acf-fields.-left {
	
	/* hide sidebar bg */
	> .acf-field:before { 
		display: none;
	}
	
	
	/* mobile */
	@media screen and (max-width: 782px) {
		
		/* remove padding */
		& > .acf-field > .acf-label,
		& > .acf-field > .acf-input {
			padding: 0;	
		}
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Inputs
*
*---------------------------------------------------------------------------------------------*/

.acf-field {
	input[type="text"],
	input[type="password"],
	input[type="date"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="email"],
	input[type="month"],
	input[type="number"],
	input[type="search"],
	input[type="tel"],
	input[type="time"],
	input[type="url"],
	input[type="week"],
	textarea,
	select {
	    width: 100%;
		padding: 4px 8px;
		margin: 0;
		box-sizing: border-box;
	    font-size: 14px;
	    line-height: 1.4;
	    
	    // WP Admin 3.8
		@include wp-admin('3-8') {
			padding: 3px 5px;
		}
	}
	textarea {
		resize: vertical;
	}
}

// Fix extra padding in Firefox.
body.acf-browser-firefox .acf-field select {
	padding: 4px 5px;
}


/*---------------------------------------------------------------------------------------------
*
*  Text
*
*---------------------------------------------------------------------------------------------*/
.acf-input-prepend,
.acf-input-append,
.acf-input-wrap {
	box-sizing: border-box;
}

.acf-input-prepend,
.acf-input-append {
	font-size: 14px;
	line-height: 1.4;
	padding: 4px 8px;
	background: #f5f5f5;
	border: $wp-input-border solid 1px;
	min-height: 30px;
	
	// WP Admin 3.8
	@include wp-admin('3-8') {
		padding: 3px 5px;
		border-color: $wp38-input-border;
		min-height: 28px;
	}
}

.acf-input-prepend {
	float: left;
	border-right-width: 0;
	border-radius: 3px 0 0 3px;
}

.acf-input-append {
	float: right;
	border-left-width: 0;
	border-radius: 0 3px 3px 0;
}

.acf-input-wrap {
	position: relative;
	overflow: hidden;
	.acf-is-prepended {
		border-radius: 0 3px 3px 0 !important;
	}
	.acf-is-appended {
		border-radius: 3px 0 0 3px !important;
	}
	.acf-is-prepended.acf-is-appended {
		border-radius: 0 !important;
	}
}

/* rtl */
html[dir="rtl"] .acf-input-prepend {
	border-left-width: 0;
	border-right-width: 1px;
	border-radius: 0 3px 3px 0;
	
	float: right;
}

html[dir="rtl"] .acf-input-append {
	border-left-width: 1px;
	border-right-width: 0;
	border-radius: 3px 0 0 3px;
	float: left;
}

html[dir="rtl"] input.acf-is-prepended {
	border-radius: 3px 0 0 3px !important;
}

html[dir="rtl"] input.acf-is-appended {
	border-radius: 0 3px 3px 0 !important;
}

html[dir="rtl"] input.acf-is-prepended.acf-is-appended {
	border-radius: 0 !important;
}


/*---------------------------------------------------------------------------------------------
*
*  Color Picker
*
*---------------------------------------------------------------------------------------------*/

.acf-color-picker {
	.wp-color-result {
		border-color: $wp-input-border;
		@include wp-admin('3-8') {
			border-color: $wp-card-border;
		}
	}
	.wp-picker-active {
		position: relative;
		z-index: 1;
	}
}


/*---------------------------------------------------------------------------------------------
*
*  Url
*
*---------------------------------------------------------------------------------------------*/

.acf-url {
	
	i {
		position: absolute;
		top: 5px;
		left: 5px;
		opacity: 0.5;
		color: #7e8993;
	}
	
	input[type="url"] {
		padding-left: 27px !important;
	}
	
	&.-valid i {
		opacity: 1;
	}

}

/*---------------------------------------------------------------------------------------------
*
*  Select2 (v3)
*
*---------------------------------------------------------------------------------------------*/

.select2-container.-acf {
	
	.select2-choices {
		background: #fff;
	    border-color: #ddd;
	    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07) inset;
	    min-height: 31px;
	    
	    .select2-search-choice {
			margin: 5px 0 5px 5px;
			padding: 3px 5px 3px 18px;
			border-color: #bbb;
			background: #f9f9f9;
			box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset;
			
			/* sortable item*/
			 &.ui-sortable-helper {
				 background: #5897fb;
				 border-color: darken(#5897fb, 5%);
				 color: #fff;
				 box-shadow: 0 0 3px rgba(0,0,0,0.1);
				 
				 a {
					 visibility: hidden;
				 }
			 }
			 
			 
			 /* sortable shadow */
			 &.ui-sortable-placeholder {
				 background-color: #f7f7f7;
				 border-color: #f7f7f7;
				 visibility: visible !important;
			 }
		}
		
		.select2-search-choice-focus {
			border-color: #999;
		}
		
		.select2-search-field input {
			height: 31px;
			line-height: 22px;
			margin: 0;
			padding: 5px 5px 5px 7px;
		}
	}
	
	.select2-choice {
	    border-color: #BBBBBB;
	    
	    .select2-arrow {
			background: transparent;
			border-left-color: #DFDFDF;
			padding-left: 1px;
		}
		
		.select2-result-description { 
			display: none;	
		}
	}
	
	
	
	/* open */
	&.select2-container-active .select2-choices,
	&.select2-dropdown-open .select2-choices {
		border-color: #5B9DD9;
		border-radius: 3px 3px 0 0;
	}
	
	
	/* single open */
	&.select2-dropdown-open .select2-choice {
		background: #fff;
		border-color: #5B9DD9;
	}
	
}

/* rtl */
html[dir="rtl"] .select2-container.-acf {
	
	.select2-search-choice-close {
	    left: 24px;
	}
	
	.select2-choice > .select2-chosen {
	    margin-left: 42px;
	}
	
	.select2-choice .select2-arrow {
	    padding-left: 0;
	    padding-right: 1px; 
	}
}


/* description */
.select2-drop {
	
	/* search*/
	.select2-search {
		padding: 4px 4px 0;
	}
	
	
	/* result */
	.select2-result {
	
		.select2-result-description {
			color: #999;
			font-size: 12px;
			margin-left: 5px;
		}
		
		
		/* hover*/
		&.select2-highlighted {
			
			.select2-result-description {
				color: #fff;
				opacity: 0.75;
			}
			
		}
	
	}
	
}



/*---------------------------------------------------------------------------------------------
*
*  Select2 (v4)
*
*---------------------------------------------------------------------------------------------*/
.select2-container.-acf {
	// Reset WP default style.
	li {
		margin-bottom: 0;
	}

	// select2 4.1 specific targeting for plugin conflict resolution.
	&[data-select2-id^='select2-data'] {
		.select2-selection--multiple {
			overflow: hidden;
		}
	}

	// Customize border color to match WP admin.
	.select2-selection {
		border-color: $wp-input-border;

		// WP Admin 3.8
		@include wp-admin('3-8') {
			border-color: #aaa;
		}
	}

	// Multiple wrap.
	.select2-selection--multiple {

		// If no value, increase hidden search input full width.
		// Overrides calculated px width issues.
		.select2-search--inline:first-child {
			float: none;
			input {
				width: 100% !important;
			}
		}

		// ul: Remove padding because li already has margin-right.
		.select2-selection__rendered {
			padding-right: 0;
		}

		// incredibly specific targeting of an ID that only gets applied in select2 4.1 to solve plugin conflicts
		.select2-selection__rendered[id^='select2-acf-field'] {
			display: inline;
			padding: 0;
			margin: 0;

			.select2-selection__choice {
				margin-right: 0;
			}
		}

		// li
		.select2-selection__choice {
			background-color: #f7f7f7;
			border-color: #cccccc;

			// Allow choice to wrap multiple lines.
			max-width: 100%;
			overflow: hidden;
			word-wrap: normal !important;
			white-space: normal;

			// Sortable.
			&.ui-sortable-helper {
				background: #5897fb;
				border-color: darken(#5897fb, 5%);
				color: #fff;
				box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

				span {
					visibility: hidden;
				}
			}

			// Fixed for select2's 4.1 css changes when loaded by another plugin.
			.select2-selection__choice__remove {
				position: static;
				border-right: none;
				padding: 0;
			}

			// Sortable shadow
			&.ui-sortable-placeholder {
				background-color: #f7f7f7;
				border-color: #f7f7f7;
				visibility: visible !important;
			}
		}

		// search
		.select2-search__field {
			box-shadow: none !important;
			min-height: 0;
		}
	}

	// Fix single select pushing out repeater field table width.
	.acf-row & .select2-selection--single {
		overflow: hidden;
		.select2-selection__rendered {
			white-space: normal;
		}
	}
}

// remove bottom margin on options
.select2-dropdown .select2-results__option {
	margin-bottom: 0;
}

// z-index helper.
.select2-container {
	.select2-dropdown {
		z-index: 900000;

		// Reset input height.
		.select2-search__field {
			line-height: 1.4;
			min-height: 0;
		}
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Link
*
*---------------------------------------------------------------------------------------------*/

.acf-link {
	
	.link-wrap {
		display: none;
		border: $wp-card-border solid 1px;
		border-radius: 3px;
		padding: 5px;
		line-height: 26px;
		background: #fff;
		
		word-wrap: break-word;
		word-break: break-all;
		
		.link-title {
			padding: 0 5px;
		}
	}
	
	// Has value.
	&.-value {
		.button {
			display: none;
		}
		.acf-icon.-link-ext {
			display: none;
		}
		.link-wrap {
			display: inline-block;
		}
	}
	
	// Is external.
	&.-external {
		.acf-icon.-link-ext {
			display: inline-block;
		}
	}
}

#wp-link-backdrop { 
	z-index: 900000 !important;
}
#wp-link-wrap { 
	z-index: 900001 !important;
}

/*---------------------------------------------------------------------------------------------
*
*  Radio
*
*---------------------------------------------------------------------------------------------*/

ul.acf-radio-list,
ul.acf-checkbox-list {
	background: transparent;
	position: relative;
	padding: 1px;
	margin: 0;
	
	li {
		font-size: 13px;
		line-height: 22px;
		margin: 0;
		position: relative;
		word-wrap: break-word;
		
		label {
			display: inline;
		}

		input[type="checkbox"],
		input[type="radio"] {
			margin: -1px 4px 0 0;
			vertical-align: middle;
		}
		
		input[type="text"] {
			width: auto;
			vertical-align: middle;
			margin: 2px 0;
		}
		
		
		/* attachment sidebar fix*/
		span {
			float: none;
		}
		
		i {
			vertical-align: middle;
		}
	}
	
	
	/* hl */
	&.acf-hl {
		
		li {
			margin-right: 20px;
			clear: none;
		}
	}
	
	
	/* rtl */
	html[dir="rtl"] & {
		
		input[type="checkbox"],
		input[type="radio"] {
			margin-left: 4px;
			margin-right: 0;
		}
	}
	
	
	
	
}


/*---------------------------------------------------------------------------------------------
*
*  Button Group
*
*---------------------------------------------------------------------------------------------*/

.acf-button-group {
	display: inline-block;
	
	label {
		display: inline-block;
		border: $wp-input-border solid 1px;
		position: relative;
		z-index: 1;
		padding: 5px 10px;
		background: #fff;
		
		&:hover {
			color: #016087;
			background: #f3f5f6;
			border-color: #0071a1;
			z-index: 2;
		}
		
		&.selected {
			border-color: #007cba;
			background: lighten(#007cba, 5%);
			color: #fff;
			z-index: 2;
		}
	}
	
	input {
		display: none !important;
	}
	
	
	/* default (horizontal) */
	& {
		padding-left: 1px;
		display: inline-flex;
		flex-direction: row;
		flex-wrap: nowrap;
		
		label {
			margin: 0 0 0 -1px;
			flex: 1;
			text-align: center;
			white-space: nowrap;
		
			// corners
			&:first-child { 
				border-radius: 3px 0 0 3px;
				html[dir="rtl"] & { border-radius: 0 3px 3px 0; }
			}
			&:last-child { 
				border-radius: 0 3px 3px 0;
				html[dir="rtl"] & { border-radius: 3px 0 0 3px; }
			}
			&:only-child { border-radius: 3px; }
		}
	}
	
	
	/* vertical */
	&.-vertical {
		padding-left: 0;
		padding-top: 1px;
		flex-direction: column;
		
		label {
			margin: -1px 0 0 0;
			
			// corners
			&:first-child { border-radius: 3px 3px 0 0; }
			&:last-child { border-radius: 0 0 3px 3px; }
			&:only-child { border-radius: 3px; }
			
		}
	}
	
	// WP Admin 3.8
	@include wp-admin('3-8') {
		label {
			border-color: $wp-card-border;
			&:hover {
				border-color: #0071a1;
			}
			&.selected {
				border-color: #007cba;
			}
		}
	}
}



/*---------------------------------------------------------------------------------------------
*
*  Checkbox
*
*---------------------------------------------------------------------------------------------*/

.acf-checkbox-list {
	
	.button {
		margin: 10px 0 0;
	}
	
}



/*---------------------------------------------------------------------------------------------
*
*  True / False
*
*---------------------------------------------------------------------------------------------*/
.acf-switch {
	display: inline-block;
	border-radius: 5px;
	cursor: pointer;
	position: relative;
	background: #f5f5f5;
	height: 30px;
	vertical-align: middle;
	border: $wp-input-border solid 1px;
	
	-webkit-transition: background 0.25s ease;
	-moz-transition: background 0.25s ease;
	-o-transition: background 0.25s ease;
	transition: background 0.25s ease;
	
	span {
		display: inline-block;
		float: left;
		text-align: center;
		
		font-size: 13px;
		line-height: 22px;
		
		padding: 4px 10px;
		min-width: 15px;
		
		i {
			vertical-align: middle;
		}
	}
	
	.acf-switch-on {
		color: #fff;
		text-shadow: #007cba 0 1px 0;
	}
	
	.acf-switch-off {
		
	}
	
	.acf-switch-slider {
		position: absolute;
		top: 2px;
		left: 2px;
		bottom: 2px;
		right: 50%;
		z-index: 1;
		background: #fff;
		border-radius: 3px;
		border: $wp-input-border solid 1px;
		
		-webkit-transition: all 0.25s ease;
		-moz-transition: all 0.25s ease;
		-o-transition: all 0.25s ease;
		transition: all 0.25s ease;
		
		transition-property: left, right;
	}
	
	/* hover */
	&:hover,
	&.-focus {
		border-color: #0071a1;
		background: #f3f5f6;
		color: #016087;
		.acf-switch-slider{
			border-color: #0071a1;
		}
	}
	
	/* active */
	&.-on {
		background: #0d99d5;
		border-color: #007cba;
		
		.acf-switch-slider {
			left: 50%;
			right: 2px;
			border-color: #007cba;
		}
		
		/* hover */
		&:hover {
			border-color: #007cba;
		}
	}
	
	/* message */
	+ span {
		margin-left: 6px;
	}
	
	// WP Admin 3.8
	@include wp-admin('3-8') {
		border-color: $wp-card-border;
		.acf-switch-slider {
			border-color: $wp-card-border;
		}
		
		&:hover,
		&.-focus {
			border-color: #0071a1;
			.acf-switch-slider{
				border-color: #0071a1;
			}
		}
		
		&.-on {
			border-color: #007cba;
			.acf-switch-slider {
				border-color: #007cba;
			}
			&:hover {
				border-color: #007cba;
			}
		}
	}
}


/* checkbox */
.acf-switch-input {
	opacity: 0;
	position: absolute;
	margin: 0;
}


/* in media modal */
.compat-item .acf-true-false {
	
	.message {
		float: none;
		padding: 0;
		vertical-align: middle;
	}
	
}


/*--------------------------------------------------------------------------
*
*	Google Map
*	
*-------------------------------------------------------------------------*/

.acf-google-map {
	position: relative;
	border: $wp-card-border solid 1px;
	background: #fff;
	
	.title {
		position: relative;
		border-bottom: $wp-card-border solid 1px;
		
		.search {
			margin: 0;
			font-size: 14px;
			line-height: 30px;
			height: 40px;
			padding: 5px 10px;
			border: 0 none;
			box-shadow: none;
			border-radius: 0;
			font-family: inherit;
			cursor: text;
		}
		
		.acf-loading {
			position: absolute;
			top: 10px;
			right: 11px;
			display: none;
		}
		
		// Avoid icons disapearing when click/blur events conflict.
		.acf-icon:active {
			display: inline-block !important;
		}
	}
	
	.canvas {
		height: 400px;
	}
	
	// Show actions on hover.
	&:hover .title .acf-actions { 
		display: block;
	}
	
	// Default state (show locate, hide search and cancel).
	.title {
		.acf-icon.-location {
			display: inline-block;
		}
		.acf-icon.-cancel,
		.acf-icon.-search {
			display: none;
		}
	}
	
	// Has value (hide locate, show cancel).
	&.-value .title { 
		.search {
			font-weight: bold;
		}
		.acf-icon.-location {
			display: none;
		}
		.acf-icon.-cancel {
			display: inline-block;
		}
	}
	
	// Is searching (hide locate, show search and cancel).
	&.-searching .title {
		.acf-icon.-location {
			display: none;
		}
		.acf-icon.-cancel,
		.acf-icon.-search {
			display: inline-block;
		}
		
		// Show actions.
		.acf-actions { 
			display: block;
		}
		
		// Change search font-weght.
		.search {
			font-weight: normal !important;
		}
	}
	
	// Loading.
	&.-loading .title {
		a {
			display: none !important;
		}
		i {
			display: inline-block;
		}
	}
	
}



/* autocomplete */
.pac-container {
	border-width: 1px 0;
	box-shadow: none;
}

.pac-container:after {
	display: none;
}

.pac-container .pac-item:first-child {
	border-top: 0 none;
}
.pac-container .pac-item {
	padding: 5px 10px;
	cursor: pointer;
}


html[dir="rtl"] .pac-container .pac-item {
	text-align: right;
}
	
	
/*--------------------------------------------------------------------------
*
*	Relationship
*	
*-------------------------------------------------------------------------*/

.acf-relationship {
	background: #fff;
	border: $wp-card-border solid 1px;
	
	// Filters.
	.filters {
		@include clearfix();
		border-bottom: $wp-card-border solid 1px;
		background: #fff;
		
		.filter {
			margin: 0;
			padding: 0;
			float: left;
			width: 100%;
			box-sizing: border-box;
			padding: 7px 7px 7px 0;
			&:first-child {
				padding-left: 7px;
			}

			// inputs
			input, select {
				margin: 0;
				float: none; /* potential fix for media popup? */
				
				&:focus,
				&:active {
					outline: none;
					box-shadow: none;
				}
			}
			input {
				border-color: transparent;
				box-shadow: none;
				padding-left: 3px;
				padding-right: 3px;
			}
		}
		
		/* widths */
		&.-f2 {
			.filter { width: 50%; }
		}
		&.-f3 {
			.filter { width: 25%; }
			.filter.-search { width: 50%; }
		}
	}
	
	
	/* list */
	.list {
		margin: 0;
		padding: 5px;
		height: 160px;
		overflow: auto;
		
		.acf-rel-label,
		.acf-rel-item,
		p {
			padding: 5px;
			margin: 0;
			display: block;
			position: relative;
			min-height: 18px;
		}
		
		.acf-rel-label {
			font-weight: bold;
		}
		
		.acf-rel-item {
			cursor: pointer;
			
			b {
				text-decoration: underline;
				font-weight: normal;
			}
			
			.thumbnail {
				background: darken(#f9f9f9, 10%);
				width: 22px;
				height: 22px;
				float: left;
				margin: -2px 5px 0 0;
				
				img {
					max-width: 22px;
					max-height: 22px;
					margin: 0 auto;
					display: block;
				}
				
				&.-icon {
					background: #fff;
					
					img {
						max-height: 20px;
						margin-top: 1px;
					}
				}
			}
			
			/* hover */
			&:hover {
				background: #3875D7;
				color: #fff;
				
				.thumbnail {
					background: lighten(#3875D7, 25%);
					
					&.-icon {
						background: #fff;
					}
				}
			}
			
			
			/* disabled */
			&.disabled {
				opacity: 0.5;
				
				&:hover {
					background: transparent;
					color: #333;
					cursor: default;
					
					.thumbnail {
						background: darken(#f9f9f9, 10%);
						
						&.-icon {
							background: #fff;
						}
					}
				}
			}
		}

		ul {
			padding-bottom: 5px;
			
			.acf-rel-label,
			.acf-rel-item,
			p {
				padding-left: 20px;
			}
			
		}
		
	}
	
	
	/* selection (bottom) */
	.selection {
		@include clearfix();
		position: relative;
		
		.values,
		.choices {
			width: 50%;
			background: #fff;
			float: left
		}
		
		/* choices */
		.choices {
			background: #F9F9F9;
			
			.list {
				border-right: #DFDFDF solid 1px;
			}

		}
		
		
		/* values */
		.values {
			
			.acf-icon {
				position: absolute;
				top: 4px;
				right: 7px;
				display: none;
				
				/* rtl */
				html[dir="rtl"] & {
					right: auto;
					left: 7px;
				}
				
			}
			
			.acf-rel-item:hover .acf-icon {
				display: block;
			}

			.acf-rel-item {
				cursor: move;
				
				b {
					text-decoration: none;
				}

			}

		}
		
	}


}

/* menu item fix */
.menu-item {
	
	.acf-relationship {
		
		ul {
			width: auto;
		}
		
		li {
			display: block;
		}
	}
}


/*--------------------------------------------------------------------------
*
*	WYSIWYG
*	
*-------------------------------------------------------------------------*/

.acf-editor-wrap {
	
	// Delay.
	&.delay {
		
		.acf-editor-toolbar {
			content: "";
			display: block;
			background: #f5f5f5;
			border-bottom: #dddddd solid 1px;
			color: #555d66;
			padding: 10px;
		}
		
		.wp-editor-area {
		    padding: 10px;
		    border: none;
		    color: inherit !important; // Fixes white text bug.
		}
	}
	
	iframe{ 
		min-height: 200px;
	}
	
	.wp-editor-container{ 
		border: 1px solid $wp-card-border;
		box-shadow: none !important;
	}
	
	.wp-editor-tabs {
		box-sizing: content-box;
	}
	
	.wp-switch-editor {
		border-color: $wp-card-border;
		border-bottom-color: transparent;
	}
}

// Full Screen Mode.
#mce_fullscreen_container {
	z-index: 900000 !important;
}

/*---------------------------------------------------------------------------------------------
*
*	Tab
*
*---------------------------------------------------------------------------------------------*/

.acf-field-tab {
	display: none !important;
}

// class to hide fields
.hidden-by-tab {
	display: none !important;
}

// ensure floating fields do not disturb tab wrap
.acf-tab-wrap {
	clear: both;
	z-index: 1;
}

// tab group
.acf-tab-group {
	border-bottom: #ccc solid 1px;
	padding: 10px 10px 0;
	
	li {
	    margin: 0 0.5em 0 0;
	    
	    a {
			padding: 5px 10px;
			display: block;
			
			color: #555;
			font-size: 14px;
		    font-weight: 600;
		    line-height: 24px;
		    
		    border: #ccc solid 1px;
		    border-bottom: 0 none;
		    text-decoration: none;
		    background: #e5e5e5;
		    transition: none;
		    
		    &:hover {
				background: #FFF;
			}
			
			&:focus {
				outline: none;
				box-shadow: none;
			}
			
			&:empty {
				display: none;
			}
			
		}
		
		
		// rtl
		html[dir="rtl"] & {
			margin: 0 0 0 0.5em;
		}
		
		
		// active
		&.active a {
			background: #F1F1F1;
			color: #000;
		    padding-bottom: 6px;
		    margin-bottom: -1px;
		    position: relative;
		    z-index: 1;
		}
	}
}


// inside acf-fields
.acf-fields > .acf-tab-wrap {
	background: #F9F9F9;
	
	// group
	.acf-tab-group {
		position: relative;
		border-top: $wp-card-border solid 1px;
		border-bottom: $wp-card-border solid 1px;
		
		// Pull next element (field) up and underneith.
		z-index: 2;
		margin-bottom: -1px;
		
		li a {
			background: #f1f1f1;
			border-color: $wp-card-border;
			
			&:hover {
				background: #FFF;
			}
		}
			
		li.active a {
			background: #FFFFFF;
		}
		
		// WP Admin 3.8
		@include wp-admin('3-8') {
			border-color: $wp38-card-border-1;
		}
	}
	
	// first child
	// fixes issue causing double border-top due to WP postbox .handlediv
	&:first-child .acf-tab-group {
		border-top: none;
	}
	
}


// inside acf-fields.-left 
.acf-fields.-left > .acf-tab-wrap {
	
	// group
	.acf-tab-group {
		padding-left: 20%;
		
		/* mobile */
		@media screen and (max-width: $sm) {
			padding-left: 10px;
		}
		
		
		/* rtl */
		html[dir="rtl"] & {
			padding-left: 0;
			padding-right: 20%;
			
			/* mobile */
			@media screen and (max-width: 850px) {
				padding-right: 10px;
			}
		}
	}
}


// left
.acf-tab-wrap.-left {
	
	// group
	.acf-tab-group {
		position: absolute;
		left: 0;
		width: 20%;
		border: 0 none;
		padding: 0 !important; /* important overrides 'left aligned labels' */
		margin: 1px 0 0;
		
		// li
		li {
			float: none;
			margin: -1px 0 0;
			
			a {
				border: 1px solid #ededed;
				font-size: 13px;
				line-height: 18px;
				color: #0073aa;
				padding: 10px;
				margin: 0;
				font-weight: normal;
				border-width: 1px 0;
				border-radius: 0;
				background: transparent;
				
				&:hover {
					color: #00a0d2;
				}
			}
			
			&.active a {
				border-color:#DFDFDF;
				color: #000;
				margin-right: -1px;
				background: #fff;
			}
		}
		
		// rtl
		html[dir="rtl"] & {
			left: auto;
			right: 0;
			
			li.active a {
				margin-right: 0; 
				margin-left: -1px;
			}
		}
	}
	
	// space before field
	.acf-field + &:before {
		content: "";
		display: block;
		position: relative;
		z-index: 1;
		height: 10px;
		border-top: #DFDFDF solid 1px;
		border-bottom: #DFDFDF solid 1px;
		margin-bottom: -1px;
	}
	
	
	// first child has negative margin issues
	&:first-child {
		
		.acf-tab-group {
			
			li:first-child a {
				border-top: none;
			}
		}
	}
}


/* sidebar */
.acf-fields.-sidebar {
	padding: 0 0 0 20% !important;
	position: relative;
	
	/* before */
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 20%;
		bottom: 0;
		border-right: #DFDFDF solid 1px;
		background: #F9F9F9;
		z-index: 1;
	}
	
	
	/* rtl */
	html[dir="rtl"] & {
		padding: 0 20% 0 0 !important;
		
		&:before {
			border-left: #DFDFDF solid 1px;
			border-right-width: 0;
			left: auto;
			right: 0;
		}
	}
	
	
	// left
	&.-left {
		padding: 0 0 0 180px !important;
		
		/* rtl */
		html[dir="rtl"] & {
			padding: 0 180px 0 0 !important;
		}
		
		&:before {
			background: #F1F1F1;
			border-color: #dfdfdf;
			width: 180px;
		}
		
		> .acf-tab-wrap.-left .acf-tab-group {
			width: 180px;
			
			li a {
				border-color: #e4e4e4;
			}
			
			li.active a {
				background: #F9F9F9;
			}
			
		}
	}
	
	// fix double border
	> .acf-field-tab + .acf-field {
		border-top: none;
	}	
}


// clear
.acf-fields.-clear > .acf-tab-wrap {
	background: transparent;
	
	// group
	.acf-tab-group {
		margin-top: 0;
		border-top: none;
		padding-left: 0;
		padding-right: 0;
		
		li a {
			background: #e5e5e5;
			
			&:hover {
				background: #fff;
			}
		}
		
		li.active a {
			background: #f1f1f1;
		}
	}
}


/* seamless */
.acf-postbox.seamless {
	
	// sidebar
	> .acf-fields.-sidebar {
		margin-left: 0 !important;
		
		&:before {
			background: transparent;
		}
	}
	
	// default
	> .acf-fields > .acf-tab-wrap {
		background: transparent;
		margin-bottom: 10px;
		padding-left: $fx;
		padding-right: $fx;
		
		.acf-tab-group {
			border-top: 0 none;
			border-color: $wp-card-border;
			
			li a {
				background: #e5e5e5;
				border-color: $wp-card-border;
				
				&:hover {
					background: #fff;
				}
			}
			
			li.active a {
				background: #f1f1f1;
			}
		}
	}
	
	// left tabs
	> .acf-fields > .acf-tab-wrap.-left {
		
		&:before {
			border-top: none;
			height: auto;
		}
		
		.acf-tab-group {
			margin-bottom: 0;
			
			li a {
				border-width: 1px 0 1px 1px !important;
				border-color: #cccccc;
				background: #e5e5e5;
			}
			
			li.active a {
				background: #f1f1f1;
			}
		}
		
	}
}


// menu
.menu-edit, 
.widget {
	.acf-fields.-clear > .acf-tab-wrap .acf-tab-group li {
		a { background: #f1f1f1; }
		a:hover, &.active a { background: #fff; }
	}
}

.compat-item .acf-tab-wrap td {
	display: block;
}



/* within gallery sidebar */
.acf-gallery-side .acf-tab-wrap {
	border-top: 0 none !important;
}

.acf-gallery-side .acf-tab-wrap .acf-tab-group {
	margin: 10px 0 !important;
	padding: 0 !important;
}

.acf-gallery-side .acf-tab-group li.active a {
	background: #F9F9F9 !important;
}



/* withing widget */
.widget .acf-tab-group {
	border-bottom-color: #e8e8e8;
}

.widget .acf-tab-group li a {
	background: #F1F1F1;
}

.widget .acf-tab-group li.active a {
	background: #fff;
}


/* media popup (edit image) */
.media-modal.acf-expanded .compat-attachment-fields > tbody > tr.acf-tab-wrap .acf-tab-group {
	padding-left: 23%;
	border-bottom-color: #DDDDDD;
}


/* table */


.form-table > tbody > tr.acf-tab-wrap .acf-tab-group {
	padding: 0 5px 0 210px;
}

/* rtl */
	html[dir="rtl"] .form-table > tbody > tr.acf-tab-wrap .acf-tab-group {
		padding: 0 210px 0 5px;
	}


/*--------------------------------------------------------------------------------------------
*
*	oembed
*
*--------------------------------------------------------------------------------------------*/

.acf-oembed {
	position: relative;
	border: $wp-card-border solid 1px;
	background: #fff;
	
	.title {
		position: relative;
		border-bottom: $wp-card-border solid 1px;
		padding: 5px 10px;
		
		.input-search {
			margin: 0;
			font-size: 14px;
			line-height: 30px;
			height: 30px;
			padding: 0;
			border: 0 none;
			box-shadow: none;
			border-radius: 0;
			font-family: inherit;
			cursor: text;
		}
		
		.acf-actions {
			padding: 6px;
		}
	}
	
	.canvas {
		position: relative;
		min-height: 250px;
		background: #F9F9F9;
		
		.canvas-media {
			position: relative;
			z-index: 1;
		}
		
		iframe {
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;
		}
		
		.acf-icon.-picture {
			@include centered();
			z-index: 0;
			
			height: 42px;
			width: 42px;
			font-size: 42px;
			color: #999;
		}
		
		.acf-loading-overlay {
			background: rgba(255,255,255,0.9);
		}
		
		.canvas-error {
			position: absolute;
			top: 50%;
			left: 0%;
			right: 0%;
			margin: -9px 0 0 0;
			text-align: center;
			display: none;
			
			p {
				padding: 8px;
				margin: 0;
				display: inline;
			}
		}
	}
	
	// has value
	&.has-value {
		.canvas {
			min-height: 50px;
		}
		
		.input-search {
			font-weight: bold;
		}
		
		.title:hover .acf-actions {
			display: block;
		}
	}

}

/*--------------------------------------------------------------------------------------------
*
*	Image
*
*--------------------------------------------------------------------------------------------*/

.acf-image-uploader {
	@include clearfix();
	position: relative;
	
	
	p {
		margin: 0;
	}
	
	
	/* image wrap*/
	.image-wrap {
		position: relative;
		float: left;
		
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			display: block;
			min-width: 30px;
			min-height: 30px;
			background: #f1f1f1;
			margin: 0;
			padding: 0;
			
			
			/* svg */
			&[src$=".svg"] {
				min-height: 100px;
				min-width: 100px;
			}
		}
		
		
		/* hover */
		&:hover .acf-actions {
			display: block;
		}
	}
	
	
	/* input */
	input.button {
		width: auto;
	}
	
	
	/* rtl */
	html[dir="rtl"] & {
		
		.image-wrap {
			float: right;
		}
		
	}
	
}


/*--------------------------------------------------------------------------------------------
*
*	File
*
*--------------------------------------------------------------------------------------------*/

.acf-file-uploader {
	position: relative;
	
	p {
		margin: 0;
	}
	
	.file-wrap {
		border: $wp-card-border solid 1px;
		min-height: 84px;
		position: relative;
		background: #fff;
	}
	
	.file-icon {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		padding: 10px;
		background: #F1F1F1;
		border-right: $wp-card-border-1 solid 1px;
		
		img {
			display: block;
			padding: 0;
			margin: 0;
			max-width: 48px;
		}
	}
	
	.file-info {
		padding: 10px;
		margin-left: 69px;
		
		p {
			margin: 0 0 2px;
			font-size: 13px;
			line-height: 1.4em;
			word-break: break-all;
		}
		
		a {
			text-decoration: none;
		}
	}
	
	/* hover */
	&:hover .acf-actions {
		display: block;
	}
	
	
	/* rtl */
	html[dir="rtl"] & { 
		
		.file-icon {
			left: auto;
			right: 0;
			border-left: #E5E5E5 solid 1px;
			border-right: none;
		}
		
		.file-info {
			margin-right: 69px;
			margin-left: 0;
		}
	}
	
}


/*---------------------------------------------------------------------------------------------
*
*	Date Picker
*
*---------------------------------------------------------------------------------------------*/

.acf-ui-datepicker .ui-datepicker {
	z-index: 900000 !important;
	
	.ui-widget-header a {
		cursor: pointer;
		transition: none;
	}
}


/* fix highlight state overriding hover / active */
.acf-ui-datepicker .ui-state-highlight.ui-state-hover {
	border: 1px solid #98b7e8 !important;
	background: #98b7e8 !important;
	font-weight: normal !important;
	color: #ffffff !important;
}

.acf-ui-datepicker .ui-state-highlight.ui-state-active {
	border: 1px solid #3875d7 !important;
	background: #3875d7 !important; 
	font-weight: normal !important;
	color: #ffffff !important;
}


/*---------------------------------------------------------------------------------------------
*
*	Separator field
*
*---------------------------------------------------------------------------------------------*/

.acf-field-separator {
	
	.acf-label {
		margin-bottom: 0;
		
		label {
			font-weight: normal;
		}
	}
	
	.acf-input {
		display: none;
	}
	
	
	/* fields */
	.acf-fields > & {
		background: #f9f9f9;
		border-bottom: 1px solid #dfdfdf;
		border-top: 1px solid #dfdfdf;
		margin-bottom: -1px;
		z-index: 2;
	}
	
}


/*---------------------------------------------------------------------------------------------
*
*	Taxonomy
*
*---------------------------------------------------------------------------------------------*/

.acf-taxonomy-field {
	position: relative;
	
	.categorychecklist-holder {
		border: $wp-card-border solid 1px;
		border-radius: 3px;
		max-height: 200px;
		overflow: auto;
	}
	
	.acf-checkbox-list {
		margin: 0;
		padding: 10px;
		
		ul.children {
			padding-left: 18px;
		}
	}
	
	
	/* hover */
	&:hover {
		.acf-actions {
			display: block;
		}
	}
	
	
	/* select */
	&[data-ftype="select"] {
		.acf-actions {
			padding: 0;
			margin: -9px;
		}
	}
	
}


/*---------------------------------------------------------------------------------------------
*
*	Range
*
*---------------------------------------------------------------------------------------------*/

.acf-range-wrap {
	
	.acf-append,
	.acf-prepend {
		display: inline-block;
		vertical-align: middle;
		line-height: 28px;
		margin: 0 7px 0 0;
	}
	
	.acf-append {
		margin: 0 0 0 7px;
	}
	
	input[type="range"] {
		display: inline-block;
		padding: 0;
		margin: 0;
		vertical-align: middle;
		height: 28px;
		
		&:focus {
			outline: none;
		}
		
	}
	
	input[type="number"] {
		display: inline-block;
		min-width: 3em;
		margin-left: 10px;
		vertical-align: middle;
	}
	
	
	/* rtl */
	html[dir="rtl"] & {
		
		input[type="number"] {
			margin-right: 10px;
			margin-left: 0;
		}
		
		.acf-append { margin: 0 7px 0 0; }
		.acf-prepend { margin: 0 0 0 7px; }
		
	}
		
}


/*---------------------------------------------------------------------------------------------
*
*  acf-accordion
*
*---------------------------------------------------------------------------------------------*/

.acf-accordion {
	margin: -1px 0;
	padding: 0;
	background: #fff;
	border-top: 1px solid $wp-card-border-1;
	border-bottom: 1px solid $wp-card-border-1;
	z-index: 1; // Display above following field.
	
	// Title.
	.acf-accordion-title {
		margin: 0;
		padding: 12px;
		font-weight: bold;
		cursor: pointer;
		font-size: inherit;
		font-size: 13px;
		line-height: 1.4em;
		
		&:hover {
			background: #f3f4f5;
		}
		
		label {
			margin: 0;
			padding: 0;
			font-size: 13px;
			line-height: 1.4em;
		}
		
		p {
			font-weight: normal;
		}
		
		.acf-accordion-icon {
			float: right;
		}
		
		// Gutenberg uses SVG.
		svg.acf-accordion-icon {
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			color: #191e23;
			fill: currentColor;
		}
	}
	
	.acf-accordion-content {
		margin: 0;
		padding: 0 12px 12px;
		display: none;
	}
	
	// Open.
	&.-open {
		> .acf-accordion-content {
			display: block;
		}
	}
}

// Field specific overrides
.acf-field.acf-accordion {
	margin: -1px 0;
	padding: 0 !important; // !important needed to avoid Gutenberg sidebar issues.
	border-color: $wp-card-border-1;
	
	.acf-label.acf-accordion-title {
		padding: 12px;
		width: auto;
		float: none;
		width: auto;
	}
	
	.acf-input.acf-accordion-content {
		padding: 0;
		float: none;
		width: auto;
		
		> .acf-fields {
			border-top: $wp-card-border-2 solid 1px;
			
			&.-clear {
				padding: 0 $fx $fy;
			}
		}
	}
}


/* field specific (left) */
.acf-fields.-left > .acf-field.acf-accordion {
	&:before {
		display: none;
	}
	
	.acf-accordion-title {
		width: auto;
		margin: 0 !important;
		padding: 12px;
		float: none !important;	
	}
	
	.acf-accordion-content {
		padding: 0 !important;
	}
}


/* field specific (clear) */
.acf-fields.-clear > .acf-field.acf-accordion {
	border: #cccccc solid 1px;
	background: transparent;
	
	+ .acf-field.acf-accordion {
		margin-top: -16px;
	}
}


/* table */
tr.acf-field.acf-accordion {
	background: transparent;
	
	> .acf-input {
		padding: 0 !important;
		border: #cccccc solid 1px;
	}
	
	.acf-accordion-content {
		padding: 0 12px 12px;
	}
}


/* #addtag */
#addtag div.acf-field.error {
	border: 0 none;
	padding: 8px 0;	
}


#addtag > .acf-field.acf-accordion {
	padding-right: 0;
	margin-right: 5%;
	
	+ p.submit {
		margin-top: 0;
	}
}



/* border */
tr.acf-accordion {
	margin: 15px 0 !important;
	
	+ tr.acf-accordion {
		margin-top: -16px !important;
	}
}


/* seamless */
.acf-postbox.seamless > .acf-fields > .acf-accordion {
	margin-left: $field_padding_x;
	margin-right: $field_padding_x;
	border: $wp-card-border solid 1px;
}


/* rtl */
html[dir="rtl"] .acf-accordion {
	
}


/* menu item */
/*
.menu-item-settings > .field-acf > .acf-field.acf-accordion {
	border: #dfdfdf solid 1px;
	margin: 10px -13px 10px -11px;
	
	+ .acf-field.acf-accordion {
		margin-top: -11px;
	}
}
*/


/* widget */
.widget .widget-content > .acf-field.acf-accordion {
	border: #dfdfdf solid 1px;
	margin-bottom: 10px;
	
	.acf-accordion-title {
		margin-bottom: 0;
	}
	
	+ .acf-field.acf-accordion {
		margin-top: -11px;
	}
}



// media modal
.media-modal .compat-attachment-fields .acf-field.acf-accordion {
	
	// siblings
	+ .acf-field.acf-accordion {
		margin-top: -1px;
	}
	
	// input
	> .acf-input {
		width: 100%;
	}
	
	// table
	.compat-attachment-fields > tbody > tr > td {
		padding-bottom: 5px;
	}
}


/*---------------------------------------------------------------------------------------------
*
*	Block Editor
*
*---------------------------------------------------------------------------------------------*/
.block-editor {
	
	// Sidebar
	.edit-post-sidebar {

		// Remove metabox hndle border to simulate component panel.
		.acf-postbox {
			> .postbox-header,
			> .hndle {
				border-bottom-width: 0 !important;
			}
			&.closed {
				>.postbox-header,
				> .hndle {
					border-bottom-width: 1px !important;
				}
			}
		}

		// Field wrap. 
		.acf-fields {
			min-height: 1px;
			overflow: auto; // Fixes margin-collapse issue in WP 5.3.

			> .acf-field {
				border-width: 0;
				border-color: #e2e4e7;
				margin: 16px;
				padding: 0;
				
				// Force full width.
				width: auto !important;
				min-height: 0 !important;
				float: none !important;
				
				// Field labels.
				> .acf-label {
					margin-bottom: 5px;
					label {
						font-weight: normal;
					}
				}
				
				// Accordions.
				&.acf-accordion {
					padding: 0;
					margin: 0;
					border-top-width: 1px;
					
					&:first-child {
						border-top-width: 0;	
					}
					
					.acf-accordion-title {
						margin: 0;
						padding: 15px;
						label {
							font-weight: bold;
						}
					}
					
					.acf-accordion-content {
						> .acf-fields {
							border-top-width: 0;
						}
					}
				}
			}
		}
	}
}
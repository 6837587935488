// Gutenberg specific styles.
#editor {
	
	// Postbox container.
	.edit-post-layout__metaboxes {
		padding: 0;
		.edit-post-meta-boxes-area {
			margin: 0;
		}
	}

	// Sidebar postbox container.
	.metabox-location-side {
		.postbox-container {
			float: none;
		}
	}
	
	// Alter postbox to look like panel component.
	.postbox {
		color: #444;
		
		> .postbox-header {
			.hndle {
				border-bottom: none;
				&:hover {
					background: transparent;
				}
			}
			.handle-actions {
				.handle-order-higher,
				.handle-order-lower {
					width: 1.62rem;
				}

				// Fix "Edit" icon height.
				.acf-hndle-cog {
					height: 44px;
					line-height: 44px;
				}
			}
			&:hover {
				background: #f0f0f0;
			}
		}
		
		// Hide bottom border of last postbox.
		&:last-child.closed > .postbox-header {
			border-bottom: none;
		}
		&:last-child > .inside {
			border-bottom: none;
		}
	}

	// Prevent metaboxes being forced offscreen.
	.block-editor-writing-flow__click-redirect {
		min-height: 50px;
	}
}

// Fix to display "High" metabox area when dragging metaboxes.
body.is-dragging-metaboxes #acf_after_title-sortables{
	outline: 3px dashed #646970;
	display: flow-root;
	min-height: 60px;
	margin-bottom: 3px !important
}